import React from 'react'
import {useNavigate} from 'react-router-dom';
import {MdArrowBack} from 'react-icons/md';

const Privacy = ({mode='mobile'}) => {

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1)
    }

  return (
    <div className="absolute z-10 w-screen overflow-hidden">
    <div className="search-page" style={{alignItems: 'unset'}}>
      <div className="toolbar flex items-center">
        <div className="go-back">
            <MdArrowBack size="26" className="text-white" onClick={goBack}/>
        </div>
        <h1 className="toolbar-title pl-1 text-md"><span className="uppercase">PRIVACY POLICY</span></h1>
      </div>
        <div className="flex flex-col w-11/12 md:w-4/6 mb-5 overflow-hidden"
          style={mode === 'desktop' ? ({
            paddingRight: '20px',
            display: 'block'
          }) : {display: 'flex'}}
        >
            <div  style={mode === 'desktop' ? ({
            height: '70vh',
          }) : ({ height: '70vh' })} className="pl-7 pt-5 overflow-y-scroll no-scrollbar">
            <h1 className="font-bold text-2xl mb-3">Privacy Policy: Gospel Hymn Book + Audio</h1>
            <p style={{ whiteSpace: 'pre-line' }}>Cartglu LLC built the Gospel Hymn Book app as a Free app. This SERVICE is provided by Cartglu LLC at no cost and is intended for use as is. Gospel hymn book was developed with love to enable people to have personal and community fellowship with God. A lot of resources available for edification and soul nourishment.
            <br/> <br/>
            This page is used to inform visitors regarding our  policies with the collection, use, and disclosure of Personal Information if anyone decided to use our  Service.
            <br/> <br/>
            If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
            <br/>  <br/>
            The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at cartglu.com/terms.html unless otherwise defined in this Privacy Policy.
            </p>
            <h3 className="font-bold text-lg mt-7">Information Collection and Use</h3>
            <p>For a better experience, while using our Service, We may require you to provide us with certain personally identifiable information, including but not limited to open network sockets, access information about networks, prevent processor from sleeping or screen from dimming, broadcast sticky intents, disable the key guard if it is not secure, modify global audio settings, access to the vibrator, write to external storage. The information that We request will be retained on your device and is not collected by me in any way.</p>
            <h3 className="font-bold text-lg mt-7">Log Data</h3>
            <p>We want to inform you that whenever you use our  Service, in a case of an error in the app We collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our  Service, the time and date of your use of the Service, and other statistics.</p>
            <h3 className="font-bold text-lg mt-7">Cookies</h3>
            <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
            <br/><br/>
            This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
            <h3 className="font-bold text-lg mt-7">Service Providers</h3>
            <div>
                <p>We may employ third-party companies and individuals due to the following reasons:</p>
                <ul className="list-disc pl-8">
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <br/>
                <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
            </div>
            <h3 className="font-bold text-lg mt-7">Security</h3>
            <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and We cannot guarantee its absolute security.</p>
            <h3 className="font-bold text-lg mt-7">Links to Other Sites</h3>
            <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, We strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
            <h3 className="font-bold text-lg mt-7">Children’s Privacy</h3>
            <p>These Services do not address anyone under the age of 9. We do not knowingly collect personally identifiable information from children under 9. In the case We discover that a child under 13 has provided me with personal information, We immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that We will be able to do necessary actions.</p>
            <h3 className="font-bold text-lg mt-7">Changes to This Privacy Policy</h3>
            <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
            <h3 className="font-bold text-lg mt-7">Contact Us</h3>
            <p>If you have any questions or suggestions about our  Privacy Policy, do not hesitate to contact me via: support@gospelhymns.cartglu.com.</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy
