import React, { useState, useEffect } from 'react';
import './NetworkStatusChecker.css'; // Import your CSS file

const NetworkStatusChecker = () => {
  const [showPopup, setShowPopup] = useState('none');

  useEffect(() => {
    const handleConnectionChange = () => {
      if (!navigator.onLine) {
        setShowPopup('block');
        setTimeout(() => {
          setShowPopup('none');
        }, 3000); // Display for 3 seconds
      }
    };

    window.addEventListener('offline', handleConnectionChange);
    window.addEventListener('online', handleConnectionChange);

    return () => {
      window.removeEventListener('offline', handleConnectionChange);
      window.removeEventListener('online', handleConnectionChange);
    };
  }, []);

  return (
    <div className={`z-20 popup`} style={{display: showPopup}}>
      No internet connection!
    </div>
  );
};

export default NetworkStatusChecker;
