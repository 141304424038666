import React, { useState, memo } from 'react';
import './styles/SearchHymn.css'; // Import your CSS file for styling
import {useLocation, useNavigate} from 'react-router-dom';
import {MdArrowBack} from 'react-icons/md';
import { useDb } from './DbContext';

const SearchHymn = () => {

    const dbContext = useDb();
        if (!dbContext) {
            // fetching records...
        }
    const { loadHymn, loadYorubaHymn, loadFrenchHymn, loadSpanishHymn} = dbContext;
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const flavor = location?.state?.flavor;

  const viewHymn = (key, tname, verse, category, flavor) => {
    const data = { 
        key,
        tname,
        verse,
        category,
        flavor,
        navpage: location?.state?.navpage
    };
    navigate('/view-hymn', { 
        state: data
    })
}

  const extractNumbers = (inputString) => {
    const numericRegex = /\d+/g;
    const numbersArray = inputString.match(numericRegex);
    
    if (numbersArray) {
        return numbersArray.join('');
    }
    
    return '';
  }

  const mergeResults = (dataOne, dataTwo) => {
    const unique = new Set();
    const prop = '_id'
    const combine = dataOne.concat(dataTwo);
    const results = combine.filter(item => {
        if (!unique.has(item[prop])) {
          unique.add(item[prop]);
          return true;
        }
        return false;
      });
    
      return results;
  }
  
  const filterOutNumbers = (objectsArray, searchTerm) => {
    const sanitizedSearchTerm = searchTerm.toLowerCase();
    const regex = new RegExp(`\\b${sanitizedSearchTerm}\\b`, 'i');

    return objectsArray.filter(obj => {
        const description = obj.verse.toLowerCase();
        return description.match(regex);
    });
  }

  const handleSearch = () => {
    let filteredResults =  []
    let finder_two, finder_one = null;
    let num = extractNumbers(searchTerm);
    if(flavor === "english"){
        finder_one = filterOutNumbers(loadHymn, searchTerm);
        finder_two = (num !== '') ? loadHymn.filter(item =>
            item.tno.toLowerCase().includes(num)
            ) : [];
    } else if(flavor === "yoruba"){
        finder_one = filterOutNumbers(loadYorubaHymn, searchTerm);
        finder_two = (num !== '') ? loadYorubaHymn.filter(item =>
            item.tno.toLowerCase().includes(num)
            ) : [];
    } else if(flavor === "spanish"){
        finder_one = filterOutNumbers(loadSpanishHymn, searchTerm);
        finder_two = (num !== '') ? loadSpanishHymn.filter(item =>
            item.tno.toLowerCase().includes(num)
            ) : [];
    } else if(flavor === "french"){
        finder_one = filterOutNumbers(loadFrenchHymn, searchTerm);
        finder_two = (num !== '') ? loadFrenchHymn.filter(item =>
            item.tno.toLowerCase().includes(num)
            ) : [];
    }
    filteredResults = mergeResults(finder_one, finder_two)
    setSearchResults(filteredResults);
  };

  const goBack = () => {
      navigate(-1)
  }

  const enableSearch = () => {
    setSearchResults([])
  }

  return (
    <div className="absolute z-20 w-screen overflow-hidden">
    <div className="search-page">
      <div className="toolbar flex items-center">
        <div className="go-back">
            <MdArrowBack size="26" className="text-white" onClick={goBack}/>
        </div>
  <h1 className="toolbar-title pl-1">Search Hymn | <span className="capitalize">{flavor}</span> Version</h1>
      </div>
      <div className="search-container mt-5">
        <div className="search-box mt-5">
          {
            flavor == "english" && (
              <p class="text-lg">What would you like to read today?</p>
            )
          }
           {
            flavor == "spanish" && (
              <p class="text-lg">¿Qué le gustaría leer hoy?</p>
            )
          }
           {
            flavor == "yoruba" && (
              <p class="text-lg">Kini o fẹ lati ka loni?</p>
            )
          }
           {
            flavor == "french" && (
              <p class="text-lg">Qu’aimeriez-vous lire aujourd’hui ?</p>
            )
          }
          
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            style={{borderRadius: 100}}
            className="mt-5 p-5 focus:ring-0 or focus:ring-transparent border-transparent hover:border-transparent focus:outline-none text-gray-500 shadow-md"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="bg-red-300 p-3 w-6/12 mt-5 text-white" onClick={handleSearch}>Search</button>
        </div>
        <div className="search-results mt-10 overflow-y-scroll">
            {searchResults  ?  (
                <>
                <ul className="list-none divide-y overflow-y-scroll pb-5 no-scrollbar" style={{height: `40vh`, }}> 
                    {
                    searchResults?.map(item => (
                        <li className="py-2 text-sm" key={item._id} onClick={() => viewHymn(item?.tno, item?.tname, item?.verse, item?.category, flavor)}>
                           <p>Hymn {item.tno}: </p>
                           <p className="font-bold">{item?.verse.substr(3, 30)}...</p>
                        </li>
                    )) 
                    }
                </ul>
                </>
            ) : null }
        </div>
      </div>
    </div>
    </div>
  );
};

export default memo(SearchHymn);
