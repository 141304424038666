import  React, {useState, useEffect} from "react"
import {useLocation, useNavigate} from 'react-router-dom';
import { useDb } from './DbContext';
import MasonryFrame from './MasonryFrame';

import {MdArrowBack} from 'react-icons/md';

const FavoritesViewDesktop = ({assets}) => {

    const localStorageTag = "hymn.adediranife.favorites"
    const location = useLocation();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);

    const dbContext = useDb();
    useEffect(() => {
        if (!dbContext) {
            // fetching records...
        }
    }, [dbContext]);

    useEffect(()=> {
        const storedFavorites = JSON.parse(localStorage.getItem(localStorageTag)) || [];
        setItems(storedFavorites);

    }, [])

    const goBack = () => {
        navigate(-1)
    }


    return (
        <div className="h-full">
            {/* <div className='w-4/5 mx-5'>
                <p className='mt-2 w-full font-bold text-2xl'>FAVORITES - Welcome to your most loved hymns</p>
                <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-200"></hr>
            </div> */}

            <div className="sticky top-0 z-10">
                <div className="toolbar flex items-center">
                    <div className="go-back">
                        <MdArrowBack size="26" className="text-white" onClick={goBack}/>
                    </div>
                    <h1 className="toolbar-title pl-1 text-sm md:text-md lg:text-lg"><span className="uppercase">{'FAVORITES - Welcome to your most loved hymns'}</span></h1>
                </div>
            </div>
            
            <MasonryFrame category='favorites' hymns={items} bgImage={assets.favoritesHeader}/>
        </div>
    )
}

export default FavoritesViewDesktop