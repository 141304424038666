import React, {useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import Card from "./Card"
import {useLocation, useNavigate} from 'react-router-dom';

const breakpointColumnsObj = {
  default: 4,
  3000: 4,
  2000: 3,
  1500: 3,
  1200: 2,
  1000: 2,
  700: 1,
  500: 1,
};

// #e4848b

const MasonryFrame = ({ hymns, bgImage, category }) => {
  
  const [cards, setCards] = useState([]);
  const heightPicker = ["32rem", "20rem", "25rem"]
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const getRandomChoice = () => {
    const randomValue = Math.random();
    const choice = Math.floor(randomValue * heightPicker.length);
    return choice;
  };

  // console.log(hymns)

  const addNewCards = () => {
    const newCards = hymns.slice(currentIndex, currentIndex + 150); // You can change the increment count (10 in this example)
    setCards((prevCards) => [...prevCards, ...newCards]);
    setCurrentIndex((prevIndex) => prevIndex + 150);
    if (currentIndex + 150 >= hymns?.length) {
        clearInterval(intervalId);
      }
  };

  // useEffect(() => {  
  //   addNewCards();
  //   const newIntervalId = setInterval(() => addNewCards(), 5000);
  //   setIntervalId(newIntervalId);
  //   return () => clearInterval(newIntervalId);
  // }, []);

  // console.log(hymns)

  return (
    <Masonry className="flex animate-slide-fwd lg:w-4/5 lg:pl-3 md:w-9/12 md:pl-2" breakpointCols={breakpointColumnsObj}>
      {category !== "authors" && category !== "gospel" && category !== "favorites"   ? (
        hymns?.map((items, index) => <Card hymns={hymns} category={category} key={items.tno} bgImage={bgImage} title={'Gospel Hymn ' + items.tno} height={heightPicker[getRandomChoice()]} description={items.verse.substring(3, 32)+"...."} className="w-max" />)) : null
      }

      {category === "authors"  ? (
        hymns?.map((items, index) => <Card hymns={hymns} category={category} key={items.tno} bgImage={bgImage} title={items.author} height={heightPicker[getRandomChoice()]} description={items.description.substring(0, 32)+"...."} className="w-max" />) ) : null
      }

      {category === "gospel"  ? (
        hymns?.map((items, index) => <Card hymns={hymns} category={category} key={items.tno} bgImage={bgImage} title={items.title} height={heightPicker[getRandomChoice()]} description={items.message.substring(0, 32)+"...."} className="w-max" />) ) : null
      }

    {category === "favorites"  ? (
        hymns?.map((items, index) => <Card hymns={hymns} category={category} key={index} bgImage={bgImage} title={'Gospel Hymn ' + items.id} height={heightPicker[getRandomChoice()]} description={""} className="w-max" />) ) : null
      }
    </Masonry>
)
  };

export default MasonryFrame;