
import React, {useState, useEffect} from 'react'
import MasonryFrame from "./MasonryFrame"
import { useDb } from './DbContext'; // Import useDb from the DbContext
// import CardInfo from "./CardInfo"
import {useLocation, useNavigate} from 'react-router-dom';
import {MdArrowBack} from 'react-icons/md';

const FlexibleFrame = ({assets}) => {

    const dbContext = useDb();
    const location = useLocation();
    const navigate = useNavigate();
    const [cards, setCards] = useState([])
    
    useEffect(() => {
        if (!dbContext) {
            // fetching records...
        }
    }, [dbContext]);

    const goBack = () => {
        navigate(-1)
    }


    return (
        <div className='h-full'>
            {/* <div className='w-4/5 mx-5 top-0'>
                <p className='mt-2 w-full font-bold text-2xl'>{location?.state?.pageTitle}</p>
                <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-200"></hr>
            </div> */}

            <div className="sticky top-0 z-10">
                <div className="toolbar flex items-center">
                    <div className="go-back">
                        <MdArrowBack size="26" className="text-white" onClick={goBack}/>
                    </div>
                    <h1 className="toolbar-title pl-1 text-md"><span className="uppercase">{location?.state?.pageTitle}</span></h1>
                </div>
            </div>

            <div className='mt-7'>
                <div className="">
                    {location?.state?.category === 'english' ? (
                        <MasonryFrame category={location?.state?.category} hymns={dbContext.loadHymn} bgImage={assets.englishHeader}/>
                    ): null}
                     {location?.state?.category === 'spanish' ? (
                        <MasonryFrame category={location?.state?.category} hymns={dbContext.loadSpanishHymn} bgImage={assets.spanishHeader}/>
                    ): null}
                     {location?.state?.category === 'french' ? (
                        <MasonryFrame category={location?.state?.category} hymns={dbContext.loadFrenchHymn} bgImage={assets.frenchHeader}/>
                    ): null}
                     {location?.state?.category === 'yoruba' ? (
                        <MasonryFrame category={location?.state?.category} hymns={dbContext.loadYorubaHymn} bgImage={assets.yorubaHeader}/>
                    ): null}
                </div>
            </div>
        </div>
    )

}

export default FlexibleFrame