// DbContext.js
import React, { createContext, useContext, useEffect, useState  } from 'react';
// import { addDataToCache, getCachedData } from '../utils/db';
import { addEncryptedDataToCache, getDecryptedData } from '../utils/db';
import axios from 'axios';

// Create a context
const DbContext = createContext();

// Create a provider component
export function DbProvider({ children }) {

    const [loadHymn, setLoadHymn] = useState(null);
    const [loadYorubaHymn, setLoadYorubaHymn] = useState(null);
    const [loadSpanishHymn, setLoadSpanishHymn] = useState(null);
    const [loadFrenchHymn, setLoadFrenchHymn] = useState(null);
    const [loadAuthorReview, setLoadAuthorReview] = useState(null);
    const [loadDoctrine, setLoadDoctrine] = useState(null);
  
    useEffect(() => {
      async function fetchInitialData(category) {
        try {
            // const cachedData = await getCachedData(category);
            const cachedData = await getDecryptedData(category);

            if (cachedData.length > 0) {
              // If data is cached, use it
              if (category === 'yoruba') {
                setLoadYorubaHymn(cachedData);
              } else if (category === 'english') {
                setLoadHymn(cachedData);
              } else if (category === 'spanish') {
                setLoadSpanishHymn(cachedData);
              } else if (category === 'french') {
                setLoadFrenchHymn(cachedData);
              } else if (category === 'author') {
                setLoadAuthorReview(cachedData);
              } else if (category === 'doctrine') {
                setLoadDoctrine(cachedData);
              }
            } else {

            // const response = await fetch(`http://localhost:8080/api/hymns/${category}`);
              const response = await fetch(`https://gospel-hymn-project-9896719c7b0b.herokuapp.com/api/hymns/${category}`);
              const jsonData = await response.json();

              if (jsonData.data && jsonData.data.length > 0) {
                // await addDataToCache(category, jsonData.data);
                await addEncryptedDataToCache(category, jsonData.data);
              }

              if(category === "yoruba"){
                  setLoadYorubaHymn(jsonData.data);
              } else if(category === "english"){
                  setLoadHymn(jsonData.data);
              } else if(category === "spanish"){
                  setLoadSpanishHymn(jsonData.data);
              } else if(category === "french"){
                  setLoadFrenchHymn(jsonData.data);
              } else if(category === "author"){
                setLoadAuthorReview(jsonData.data);
              } else if(category === "doctrine"){
                setLoadDoctrine(jsonData.data);
              }
            }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
  
      fetchInitialData("english");
      fetchInitialData("yoruba");
      fetchInitialData("spanish")
      fetchInitialData("french"); 
      fetchInitialData("author"); 
      fetchInitialData("doctrine"); 
      
    }, []);

    const contextValue = {
      loadHymn,
      loadYorubaHymn,
      loadFrenchHymn,
      loadSpanishHymn,
      loadAuthorReview,
      loadDoctrine
    };

  return (
    <DbContext.Provider value={contextValue}>
      {children}
    </DbContext.Provider>
  );
}

// Custom hook to consume the context
export function useDb() {
  return useContext(DbContext);
}
