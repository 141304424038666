
import React, {useState, useEffect} from "react"
import {useLocation, useNavigate} from 'react-router-dom';
import { useDb } from './DbContext';

const Card = ({ title, description, height, bgImage, hymns, category }) => {
    const navigate = useNavigate();
    const dbContext = useDb();
    useEffect(() => {
        if (!dbContext) {
            // fetching records...
        }
    }, [dbContext]);

    const showHymn = (index, val='english') => {
      
        if(["english", "french", "spanish", "yoruba"].includes(val)){
          index = index.replace("Gospel Hymn ", "") 
          let filter = null;
          let dt = null;
      
          filter = hymns.filter(obj => obj.tno === index);
          dt = { 
              key: filter[0].tno,
              tname: filter[0].tname,
              verse: filter[0].verse,
              category: filter[0]?.category,
              flavor: val
          };
          
          navigate('/hymn-details', {
            state: dt
          })
        }

        if(val === "favorites") {
          index = index.replace("Gospel Hymn ", "")
          // console.log(dbContext.loadHymn, index)
        let filter = dbContext.loadHymn.filter(item => item.tno === index)[0]
            let data = { 
                key: index,
                tname: filter.tname,
                verse: filter.verse,
                category: filter?.category,
                flavor: val,
                navpage: 1
            };
            navigate('/hymn-details', { 
                state: data
            })

        }

        if(val === "gospel") {
          let filter = hymns.filter(obj => obj.title === index);

          let _dt = {
            title: title,
            message: filter[0].message,
            mode: 'desktop'
          }

          navigate('/category/gospel/article', {
            state: _dt
          })
        }
        
        if(val === "authors") {

          let filter = hymns.filter(obj => obj.author === index);

          let _dt = {
            author: title,
            description: filter[0].description,
            mode: 'desktop'
          }

          navigate('/category/authors/article', {
            state: _dt
          })
        }
    
      }

    return (
    <div className="p-4 relative">
      <div className="bg-cover rounded-lg text-white relative overflow-hidden w-auto hover:shadow-lg"
        style={{
            backgroundImage: `url(${bgImage})`,
            height: height,
        }} onClick={()=> showHymn(title, category)}>
          <div style={{
            height: height,
            backgroundBlendMode: 'multiply',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'rgb(0 0 0/ 40%)',
        }}>
  
          </div>
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <p className="md:text-md lg:text-lg font-semibold">{title}</p>
        </div>
        <div className="absolute bottom-0 left-0 w-full p-4"
            style={{background: 'rgb(18 15 15 / 52%)'}}>
          <p className="text-white">{description}</p>
        </div>
      </div>
    </div>
    );
  };

export default Card