import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom';
import {MdArrowBack} from 'react-icons/md';

const Contact = () => {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        subject: '',
        message: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = (e) => {
        let email = "support@gospelhymns.cartglu.com"
        openGmailCompose(formData.name, formData.subject, formData.message, email)
      };

      const openGmailCompose = (name, subject, body, recipient) => {
        const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${encodeURIComponent(`Name: ${name} \n\n`+ body)}`;
        window.location.href = mailtoLink;
      };

    const goBack = () => {
        navigate(-1)
    }

  return (
    <div className="absolute z-20 w-screen overflow-hidden">
    <div className="contact-page">
      <div className="toolbar flex items-center">
        <div className="go-back">
            <MdArrowBack size="26" className="text-white" onClick={goBack}/>
        </div>
        <h1 className="toolbar-title pl-1 text-md"><span className="uppercase">CONTACT US</span></h1>
      </div>
    </div>
      <div className="flex flex-col h-screen mt-1 p-6 overflow-y-scroll no-scrollbar">
        <p className="text-sm mb-1">HOW CAN WE HELP YOU?</p>
        <h2 className="text-4xl font-semibold mb-4">Please complete the form below</h2>
        <form onSubmit={handleSubmit} className="space-y-4 w-12/12 mt-2">
            <div className="w-full">
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="px-4 py-2 bg-red-100 border border-gray-200 rounded focus:outline-none focus:border-gray-600"
                    required
                />
            </div>
            <div className="w-full">
                <input
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-red-100 border border-gray-200 rounded focus:outline-none focus:border-gray-600"
                    required
                />
            </div>
            <div className="">
                <textarea
                    id="message"
                    name="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full px-4 py-2 bg-red-100 border border-gray-200 rounded focus:outline-none focus:border-gray-300 resize-none"
                    rows="4"
                    required
                />
            </div>
            <button
                type="submit"
                className="px-4 w-full py-3 text-black bg-red-300 rounded focus:outline-none"
                >SEND A MESSAGE</button>
        </form>
        </div>
    </div>
  )
}

export default Contact
