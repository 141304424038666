import  React, {useState, useEffect} from "react"
import {useLocation, useNavigate} from 'react-router-dom';
import { useDb } from './DbContext';
import MasonryFrame from './MasonryFrame';
import {MdArrowBack} from 'react-icons/md';

const SearchHymnDesktop = ({assets, flavor='english'}) => {

    const localStorageTag = "hymn.adediranife.favorites"
    const location = useLocation();
    const navigate = useNavigate();
    // const [searchTerm, setSearchTerm] = useState('');
    const searchTerm = location?.state?.searchTerm;
    const [searchResults, setSearchResults] = useState([]);

    const dbContext = useDb();
    useEffect(() => {
        if (!dbContext) {
            // fetching records...
        }
        handleSearch()
    }, [dbContext]);

    const {loadHymn } = dbContext

  const viewHymn = (key, tname, verse, category, flavor) => {
    const data = { 
        key,
        tname,
        verse,
        category,
        flavor,
        navpage: location?.state?.navpage
    };
    navigate('/hymn-details', { 
        state: data
    })
}

  const extractNumbers = (inputString) => {
    const numericRegex = /\d+/g;
    const numbersArray = inputString.match(numericRegex);
    
    if (numbersArray) {
        return numbersArray.join('');
    }
    
    return '';
  }

  const mergeResults = (dataOne, dataTwo) => {
    const unique = new Set();
    const prop = '_id'
    const combine = dataOne.concat(dataTwo);
    const results = combine.filter(item => {
        if (!unique.has(item[prop])) {
          unique.add(item[prop]);
          return true;
        }
        return false;
      });
    
      return results;
  }
  
  const filterOutNumbers = (objectsArray, searchTerm) => {
    const sanitizedSearchTerm = searchTerm.toLowerCase();
    const regex = new RegExp(`\\b${sanitizedSearchTerm}\\b`, 'i');

    return objectsArray.filter(obj => {
        const description = obj.verse.toLowerCase();
        return description.match(regex);
    });
  }

  const handleSearch = () => {
    let filteredResults =  []
    let finder_two, finder_one = null;
    let num = extractNumbers(searchTerm);
    if(flavor === "english"){
        finder_one = filterOutNumbers(loadHymn, searchTerm);
        finder_two = (num !== '') ? loadHymn.filter(item =>
            item.tno.toLowerCase().includes(num)
            ) : [];
    }
    filteredResults = mergeResults(finder_one, finder_two)
    setSearchResults(filteredResults);
  };


    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className="h-full">
              <div className="sticky top-0 z-10">
                <div className="toolbar flex items-center">
                    <div className="go-back">
                        <MdArrowBack size="26" className="text-white" onClick={goBack}/>
                    </div>
                    <h1 className="toolbar-title pl-1 text-md"><span className="uppercase">SEARCH HYMN</span></h1>
                </div>
            </div>
            <MasonryFrame category='english' hymns={searchResults} bgImage={assets.englishHeader}/>
        </div>
    )
}

export default SearchHymnDesktop