import englishHeader from '../assets/imggg.webp';
// import englishHeader from '../assets/img7_head.webp';
// import englishBackground from '../assets/img7_bg.webp';
import englishBackground from '../assets/imgg.webp';
import yorubaHeader from '../assets/img9_head.webp';
import yorubaBackground from '../assets/img9_bg.webp';
import frenchHeader from '../assets/bridge_head.webp';
import frenchBackground from '../assets/bridge_bg.webp';
import spanishHeader from '../assets/amir_head.webp';
import spanishBackground from '../assets/amir_bg.webp';
import authorHeader from '../assets/img2_head.webp';
import authorBackground from '../assets/img2_bg.webp';
import favoritesHeader from '../assets/favs_img_10_head.webp';
import favoritesBackground from '../assets/favs_img_10.webp';
import gospelHeader from '../assets/img6_head.webp';
import gospelBackground from '../assets/img6_bg.webp';
import aboutusHeader from '../assets/img5_head.webp';
import aboutusBackground from '../assets/img5_bg.webp';
// import shareHeader from '../assets/img8_head.webp';
// import shareBackground from '../assets/img8_bg.webp';
// import settingsHeader from '../assets/lighthouse_head.webp';
// import settingsBackground from '../assets/lighthouse_bg.webp';
// import categoriesHeader from '../assets/img4_head.webp';
// import categoriesBackground from '../assets/img4_bg.webp';

const items = [
    {
      id: 1,
      img_header: englishHeader,
      img_background: englishBackground,
      name: 'ENGLISH HYMN',
      category: 'english'
    },
    {
      id: 2,
      img_header: yorubaHeader,
      img_background: yorubaBackground,
      name: 'YORUBA HYMN',
      category: 'yoruba'
    },
    {
        id: 3,
        img_header: frenchHeader,
        img_background: frenchBackground,
        name: 'FRENCH HYMN',
        category: 'french'
    },
    {
        id: 4,
        img_header: spanishHeader,
        img_background: spanishBackground,
        name: 'SPANISH HYMN',
        category: 'spanish'
    },
    {
        id: 5,
        img_header: authorHeader,
        img_background: authorBackground,
        name: 'AUTHOR REVIEW',
        category: 'author'
    },
    {
        id: 6,
        img_header: favoritesHeader,
        img_background: favoritesBackground,
        name: 'FAVORITES',
        category: 'favorites'
    },
    {
        id: 7,
        img_header: gospelHeader,
        img_background: gospelBackground,
        name: 'THE GOSPEL',
        category: 'gospel'
    },
    {
        id: 8,
        img_header: aboutusHeader,
        img_background: aboutusBackground,
        name: 'ABOUT US',
        category: 'aboutus'
    },
  ];

  export const categories = [
    {
      name: 'Author Review',
      path: 'authors',
      image: 'https://i.pinimg.com/750x/eb/47/44/eb4744eaa3b3ccd89749fa3470e2b0de.jpg',
    },
    {
      name: 'Gospel News',
      path: 'gospel',
      image: 'https://i.pinimg.com/236x/25/14/29/251429345940a47490cc3d47dfe0a8eb.jpg',
    },
    {
      name: 'Favorites',
      path: 'favorites',
      image: 'https://i.pinimg.com/236x/03/48/b6/0348b65919fcbe1e4f559dc4feb0ee13.jpg',
    },
    {
      name: 'About Us',
      path: 'about',
      image: 'https://i.pinimg.com/750x/66/b1/29/66b1296d36598122e6a4c5452b5a7149.jpg',
    },
  ];

const data = () => {

    return {
        items,
        categories,
        englishHeader,
        englishBackground,
        yorubaHeader,
        yorubaBackground,
        frenchHeader,
        frenchBackground,
        spanishHeader,
        spanishBackground,
        authorHeader,
        authorBackground,
        gospelBackground,
        gospelHeader,
        favoritesBackground,
        favoritesHeader,
        aboutusBackground,
        aboutusHeader
    }

}

export default data;

 