import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import NetworkStatusChecker from './NetworkStatusChecker';
import { FlexibleFrame as Frames, 
  AboutUsViewDesktop,
  AuthorViewDesktop,
  FavoritesViewDesktop,
  Articles,
  GospelNotes,
  GospelViewDesktop,
  SearchHymnDesktop,
  CardInfo, DesktopHymnPicker} from '../components';
import Privacy from "./Privacy"

const isAndroid = /Android/i.test(navigator.userAgent);
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

const FlexibleFrame = ({ assets }) => {

  return (
    <div className="">
      <div className="h-full overflow-x-hidden overflow-y-hidden" style={{marginBottom: isIOS || isAndroid ? '6rem' : '0rem'}}
      >
        <NetworkStatusChecker />
        <Routes>
          <Route path="/hymn-details" element={<CardInfo />} />
          <Route path="/hymn-cards" element={<Frames assets={assets} />} />
          <Route path="/category/authors" element={<AuthorViewDesktop assets={assets}/>} />
          <Route path="/category/gospel" element={<GospelViewDesktop assets={assets}/>} />
          <Route path="/category/favorites" element={<FavoritesViewDesktop assets={assets}/>} />
          <Route path="/category/about" element={<AboutUsViewDesktop assets={assets}/>} />
          <Route path="/category/authors/article" element={<Articles />} />
          <Route path="/category/gospel/article" element={<GospelNotes />} />
          <Route path="/privacy" element={<Privacy mode='desktop'/>} />
          <Route path="/search" element={<SearchHymnDesktop assets={assets} mode='desktop'/>} />
          <Route path="/" element={<DesktopHymnPicker assets={assets}/>} />
          {/* <Route path="/search" element={<Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default FlexibleFrame;