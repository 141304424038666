import React, { useState, useEffect } from 'react';
import { Routes, Route, Switch } from 'react-router-dom';
import { ViewHymn, DialogHymn, SearchHymn, Articles, GospelNotes, Favorites } from '../components';
import NetworkStatusChecker from './NetworkStatusChecker';
import Dashboard from './Dashboard'
import Privacy from './Privacy'
import Contact from './Contact'

import SplashScreen from './SplashScreen';

const Pages = ({ user }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSplashLoading, setIsSplashLoading] = useState(true);
  useEffect(() => {
    const lc_splash = 'hymn.adediranife.visited'
    const hasVisited = localStorage.getItem(lc_splash);
    if (hasVisited) {
        setIsSplashLoading(false);
    } else {
    localStorage.setItem(lc_splash, 'true');
    setTimeout(() => {
    setIsSplashLoading(false);
    }, 4000); 
    }
}, []);
  // throw new Error('Simulated crash');

  return (
    ( isSplashLoading ? <SplashScreen /> : 
      <div className="h-screen">
        <NetworkStatusChecker />
        <Routes>
            <Route path="/view-hymn/*" element={<ViewHymn />} />
            <Route path="/dialog-hymn" element={<DialogHymn />} />
            <Route path="/search-hymn" element={<SearchHymn />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/gospel" element={<GospelNotes />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/privacy" element={<Privacy mode='mobile'/>} /> 
            <Route path="/*" element={<Dashboard />} /> 
        </Routes>
    </div> )
  );
};

export default Pages;