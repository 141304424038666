import React, {memo} from 'react';
import './styles/NoticeDialog.css';

const NoticeDialog = ({ message, onClose, mode='mobile' }) => {
  return (
    <>
     {mode === 'mobile' && (
      <div className="absolute z-20 w-10/12 overflow-hidden">
      <div className="notice-dialog">
      <div className="iphone">
        <div className="screen">
          <div className="content">
            <p>{message}</p>
            <button className="bg-red-400" onClick={onClose}>Main Page</button>
          </div>
        </div>
      </div>
    </div>

    </div>
     )}

    {mode === 'desktop' && (
      <div className="absolute z-30 overflow-hidden">
      <div className="notice-dialog">
      <div className="iphone">
        <div className="screen h-full">
          <div className="content pt-4 w-7/12">
            <p className=''>{message}</p>
            <button style={{width:'100%'}} className="mb-3 mt-10 bg-red-400 flex w-full justify-center items-center" onClick={onClose}>Main Page</button>
          </div>
        </div>
      </div>
    </div>

    </div>
     )}
    </>
   
    
      );
};

export default memo(NoticeDialog);
