import { openDB } from 'idb';
import CryptoJS from 'crypto-js';

const DB_NAME = 'gospelhymnbook';
const DB_VERSION = 2;

export const openDatabase = async () => {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      const stores = ['yoruba', 'english', 'spanish', 'french', 'author', 'doctrine'];
      stores.forEach((category) => {
        if (!db.objectStoreNames.contains(category)) {
          db.createObjectStore(category, { keyPath: '_id' });
        }
      });
    },
  });

  return db;
};

// export const addEncryptedDataToCache = async (category, data) => {
//   const db = await openDatabase();
//   const tx = db.transaction(category, 'readwrite');
//   const store = tx.objectStore(category);
//   await Promise.all(data.map((item) => store.put(item)));
//   await tx.done;
// };

// export const getDecryptedData = async (category) => {
//   const db = await openDatabase();
//   const tx = db.transaction(category, 'readonly');
//   const store = tx.objectStore(category);
//   return store.getAll();
// };

export const addEncryptedDataToCache = async (category, data) => {
    const db = await openDatabase();
    const tx = db.transaction(category, 'readwrite');
    const store = tx.objectStore(category);
  
    try {
      // Generate a random encryption key
      const encryptionKey = await generateEncryptionKey();
  
      await Promise.all(data.map(async (item) => {
        const encryptedItem = await encryptData(item, encryptionKey);
        await store.put(encryptedItem); // Perform the 'put' operation within the transaction
      }));
  
      await tx.complete; // Complete the transaction when all operations are done
    } catch (error) {
      // Handle any errors that occur during the transaction
      console.error("Error during transaction:", error);
    }
  };
  
  export const getDecryptedData = async (category) => {
    const db = await openDatabase();
    const tx = db.transaction(category, 'readonly');
    const store = tx.objectStore(category);
  
    try {
      const encryptedData = await store.getAll();
  
      // Decrypt and return each item in the encrypted data array
      const decryptionKey = await getDecryptionKey();
      const decryptedData = await Promise.all(encryptedData.map((item) => decryptData(item, decryptionKey)));
  
      return decryptedData;
    } catch (error) {
      // Handle any errors that occur during the transaction
      console.error("Error during transaction:", error);
    }
  };
  
  // Function to generate a random encryption key
  async function generateEncryptionKey() {
    const key = await window.crypto.subtle.generateKey(
      {
        name: 'AES-GCM',
        length: 256,
      },
      true,
      ['encrypt', 'decrypt']
    );
    return key;
  }
  
  // Function to encrypt data using the encryption key
  async function encryptData(data, encryptionKey) {
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(JSON.stringify(data));
  
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const ciphertext = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv,
      },
      encryptionKey,
      encodedData
    );
  
    return {
      iv: Array.from(iv),
      ciphertext: Array.from(new Uint8Array(ciphertext)),
    };
  }
  
  // Function to get the decryption key
  async function getDecryptionKey() {
    // You may want to securely store and retrieve your encryption key here.
    // For this example, you might hardcode it, which is not recommended for production.
    // You can use a secure key management solution for production.
    const key = await generateEncryptionKey();
    return key;
  }
  
  // Function to decrypt data using the decryption key
  async function decryptData(encryptedData, decryptionKey) {
    const iv = new Uint8Array(encryptedData.iv);
    const ciphertext = new Uint8Array(encryptedData.ciphertext);
  
    const decodedData = await window.crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv,
      },
      decryptionKey,
      ciphertext
    );
  
    const textDecoder = new TextDecoder();
    const decryptedString = textDecoder.decode(decodedData);
  
    return JSON.parse(decryptedString);
  }