import React, {useEffect, useState} from 'react'
import {Routes, Route, useNavigate} from 'react-router-dom'
import Home from "./containers/Home"
import { DbProvider } from './components/DbContext';
// import SplashScreen from './containers/SplashScreen';

const App = () => {
    const navigate = useNavigate();
    // const [isSplashLoading, setIsSplashLoading] = useState(true);
    // return new Error("messafe");
    // useEffect(() => {
    //   const lc_splash = 'hymn.adediranife.visited'
    //   const hasVisited = localStorage.getItem(lc_splash);
    //   if (hasVisited) {
    //       setIsSplashLoading(false);
    //     } else {
    //       localStorage.setItem(lc_splash, 'true');
    //       setTimeout(() => {
    //       setIsSplashLoading(false);
    //       }, 2000); 
    //   }
    // }, []);

  return (
     
      // <DbProvider>
      //    { isSplashLoading ? <SplashScreen /> : (
      //     <Routes>
      //         <Route path="/*" element={<Home/>} />
      //     </Routes> 
      //   )}
      // </DbProvider>

      <DbProvider>
          <Routes>
              <Route path="/*" element={<Home/>} />
          </Routes> 
      </DbProvider>
   
  )
}

export default App
