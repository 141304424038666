import React from 'react'
import './SplashScreen.css';
// import logo from '../assets/gospel-hymn.png'
import logo from '../assets/gospel-hymn-logo.webp'

const SplashScreen = () => {
  return (
    <div className="loading-animation flex-col w-screen">
      <div className="flex flex-col -mt-10 justify-center text-center w-12/12">
        <img className="self-center w-2/4" src={logo} />
        <p className="text-white w-3/4 self-center">There is one body and Spirit, even as ye are called in one hope of your calling; One Lord, one faith, one baptism, One God and father of all, who is above all, and through all, and in you all.</p>
        <p className="font-bold mb-9 mt-3 text-white">Ephesians 4:4</p>
      </div>
      <div className="spinner"></div>
      <p className="flex">Please wait...</p>
    </div>
  )
}

export default SplashScreen
