import React, { useState, useRef, useEffect } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import logo from '../assets/gospel-hymn.png';
import { AiFillCloseCircle } from 'react-icons/ai';
import { HiMenu } from 'react-icons/hi';
import data from '../utils/data'
import Dashboard from "./Dashboard"
import {Sidebar, NoticeDialog} from "../components"
import FlexibleFrame from "./FlexibleFrame"
import './Desktop.css'

const assets = data();

const Desktop = () => {

    const [toggleSidebar, setToggleSidebar] = useState(false);
    const scrollToTopRef = useRef(null);

    const [showAboutDialog, setShowAboutDialog] = useState(false);
    const openAboutDialog = () => {
      setShowAboutDialog(true);
    };
  
    const closeAboutDialog = () => {
        setShowAboutDialog(false);
    };

    useEffect(() => {
      setTimeout(() => {
        if (scrollToTopRef.current) {
          scrollToTopRef.current.scrollIntoView({
            top: 0,
            right: 0,
            behavior: "smooth",
          });
        }
      }, 0);
    }, []);
    
  return (
    <>
         {showAboutDialog && (
              <NoticeDialog
              message="Gospel hymn book was developed with love to enable people to have personal and community fellowship with God. A lot of resources available for edification and soul nourishment. If you have any questions, please feel free to reach us via support@gospelhymns.cartglu.com. Stay blessed."
              onClose={closeAboutDialog} mode='desktop'
              />
          )} 

        {/*bg-gray-50*/}
        <div className="flex bg-white md:flex-row flex-col h-screen transition-height duration-75 ease-out" ref={scrollToTopRef}>
              <div className="hidden md:flex h-screen flex-initial">
                <Sidebar openAboutDialog={openAboutDialog} setShowAboutDialog={setShowAboutDialog} />
              </div>
              <div className="flex md:hidden flex-row">
                <div className="p-2 w-full flex flex-row md:justify-between lg:md:justify-between items-center shadow-md">
                  <HiMenu fontSize={40} className="cursor-pointer" onClick={() => setToggleSidebar(true)} />
                  <Link to="/">
                    <div className="flex flex-row items-center">
                        <img className='mx-2' src={logo} width={30} alt="logo"  /> 
                        <p className='font-bold'>GospelHymnBook</p>
                    </div>
                  </Link>
                </div>
                {toggleSidebar && (
                <div className="z-20 fixed w-4/5 bg-white h-screen overflow-y-auto shadow-md z-10 animate-slide-in">
                  <div className="absolute w-full flex justify-end items-center p-2">
                    <AiFillCloseCircle fontSize={30} className="cursor-pointer" onClick={() => setToggleSidebar(false)} />
                  </div>
                  <Sidebar openAboutDialog={openAboutDialog} closeToggle={setToggleSidebar} setShowAboutDialog={setShowAboutDialog}/>
                </div>
                )}
              </div>
            <div className="flex-1 h-screen w-screen overflow-y-scroll">
                <Routes>
                    <Route path="/*" element={<FlexibleFrame assets={assets} />} />
                </Routes>
            </div>
        </div>
    </>
  )
}

export default Desktop