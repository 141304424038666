import React, {useEffect, useState, useRef} from "react"
import {useLocation, useNavigate} from 'react-router-dom';
import navigationDown from '../assets/arrow_circle_down.svg'
import navigationUp from '../assets/arrow_circle_up.svg'
import documentIcon from '../assets/desc_fill.svg'
import favFilled from '../assets/heart_fill.svg'
import favNoFill from '../assets/heart_nofill.svg'
import shareIcon from '../assets/share_fill.svg'
import playIcon from '../assets/play_circle.svg'
import pauseIcon from '../assets/pause_circle.svg'
import switchIcon from '../assets/switch_fill.svg'
import homeIcon from '../assets/dashboard_fill.svg'
import searchIcon from '../assets/pageview_fill.svg'
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import { Document, Page, pdfjs } from "react-pdf";
import { useDb } from './DbContext';
import HymnPicker from './HymnPicker'
import {MdArrowBack} from 'react-icons/md';
import '../containers/NetworkStatusChecker.css'

const CardInfo = () => {

    const localStorageTag = "hymn.adediranife.favorites"
    const location = useLocation();
    const [title, setTitle] = useState("")
    const [likeHymn, setLikeHymn] = useState(favFilled)

    const scrollToTopRef = useRef(null);
    const navigate = useNavigate()
    const [playButton, setPlayButton] = useState(playIcon)
    const [audio, setAudio] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [url, setUrl] = useState(`https://gospel-hymn-project-9896719c7b0b.herokuapp.com/external?url=https://hymn.insertdistinct.com/pdf/${location?.state?.key}.pdf`);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState('');
    const recipient = 'support@gospelhymns.cartglu.com';
    const subject = 'Contact Us | Gospel Hymn Book';
    const body = 'Kindly share your request/questions.';
    const [favorites, setFavorites] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [backgroundHeader, setBackgroundHeader] = useState('');
    const [items, setItems] = useState([]);

    const dbContext = useDb();
    if (!dbContext) {
        // fetching records...
    }
    const { loadHymn, loadYorubaHymn, loadFrenchHymn, loadSpanishHymn} = dbContext;

    const [prevLocation, setPrevLocation] = useState(location);

    const [isMobileOrTablet, setIsMobileOrTablet] = useState(checkIfMobileOrTablet());

  useEffect(() => {
    function handleResize() {
      setIsMobileOrTablet(checkIfMobileOrTablet());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function checkIfMobileOrTablet() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    // Define the maximum dimensions for a mobile or tablet device
    const maxMobileWidth = 768; // You can adjust this value as needed
    const maxTabletWidth = 1024; // You can adjust this value as needed

    return width <= maxMobileWidth || (width <= maxTabletWidth && height <= maxMobileWidth);
  }

    useEffect(() => {
        // Check if the state data in the location has changed
        if (prevLocation.state !== location.state) {
          // Execute your logic here when location state changes
          import(`../storage/songs/${location.state.key}.mp3`)
            .then(module => {
                setAudio(new Audio(module.default));
            })
            .catch(error => {
                try {
                    audio.pause();
                } catch (error) {
                    //do nothing
                }
                setAudio(null);
                // console.log('Error loading file..location', );
            });
        }
    
        // Update the previous location state to the current location state
        setPrevLocation(location);
      }, [location, prevLocation]);

    useEffect(() => {
        if(location){
            setTitle("GOSPEL HYMN: " + location?.state?.key)
        }
        setTimeout(() => {
            if (scrollToTopRef.current) {
                // scrollToTopRef.current.scrollTo(0, 0);
              scrollToTopRef.current.scrollIntoView({
                top: 0,
                right: 0,
                behavior: "smooth",
              });
            }
          }, 0);
    }, []);

    useEffect(() => {
        // console.log(`../storage/songs/${location.state.key}.mp3`)
        import(`../storage/songs/${location.state.key}.mp3`)
        .then(module => {
            setAudio(new Audio(module.default));
        })
        .catch(error => {
            // console.log('Error loading file:', );
            setAudio(null);
        });

        return () => {
            try {
                audio.pause()
            } catch (error) {
                //do nothing
            }
           
          };

    }, []);

    useEffect(() => { //load storage items
        const storedFavorites = JSON.parse(localStorage.getItem(localStorageTag)) || [];
        setItems(storedFavorites);
        // console.log(storedFavorites, 11, location.state.key) 
        const itemIndex = storedFavorites.find(item => item.id === location.state.key);
        if (itemIndex) {
            setLikeHymn(favNoFill)
        } else {
            setLikeHymn(favFilled)
        }

        if(location.state?.category === "fr"){
            setCurrentCategory('french')
        }else if(location.state?.category === "es"){
            setCurrentCategory('spanish')
        } else if(location.state?.category?.trim() === ""){
            setCurrentCategory('yoruba')
        } else {
            setCurrentCategory('english')
        }
      }, []);

    //tname, key, verse, category, flavor

    const checkPageValidity = async () => {
        try {
          const response = await fetch(url,  {
            method: 'GET',
            referrerPolicy: 'origin',
            mode:'cors',
            headers: {
              'X-Requested-With': 'XMLHttpRequest', // Set the header here
                },
            })
    
          if (response.ok) {
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        } catch (error) {
          setIsValid(false);
        }
      };


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const checkFileExists = () => {
        const fileUrl = `../storage/songs/${location.state.key}.mp3`;
        // Attempt to fetch the file
        import(`../storage/songs/${location.state.key}.mp3`)
        .then(module => {
            // setAudio(new Audio(module.default));
            // setAudio(null)
        })
        .catch(error => {
            alert('File not available');
            try {
                setPlayButton(playIcon)
                setIsPlaying(!isPlaying);
                audio.pause();
            } catch (error) {
                //do nothing
                // console.log(error)
            }
        });
    }

    const handleOptionSelect = (option) => {
        // Perform action based on the selected option

        // console.log(`Selected option: ${option["value"]}`);
        let val = option["value"];
        let filter = null;
        let dt = null;

        // console.log(val, currentCategory)
        if(currentCategory !== val){

            if(val == "english"){
                filter = loadHymn.filter(obj => obj.tno === location.state.key);
                setCurrentCategory(val)
            } else if(val == "french"){
                filter = loadFrenchHymn.filter(obj => obj.tno === location.state.key);
                setCurrentCategory(val)
            } else if(val == "spanish"){
                filter = loadSpanishHymn.filter(obj => obj.tno === location.state.key);
                setCurrentCategory(val)
            } else if(val == "yoruba"){
                filter = loadYorubaHymn.filter(obj => obj.tno === location.state.key);
                setCurrentCategory(val)
            }

            dt = { 
                key: filter[0].tno,
                tname: filter[0].tname,
                verse: filter[0].verse,
                category: filter[0]?.category,
                navpage: location.state.navpage + 1,
                flavor: val
            };
           
            navigate('/hymn-details', { 
                state: dt
            })

            handleCloseDialog()
        }
    };

    const musicNotes = () => { 
        // console.log(1)
        const ext = ["pdf", "jpg", "png"]
        checkPageValidity()
        if(isValid){
            openModal()
        }
    }


    const openGmailCompose = () => {
        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    useEffect(() => { //save
        let favs = items.filter(item => item.isFavorite).map(item => ({id: item.id, isFavorite: true}));

        // const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
        // if(favs.length == 0){
        //     favs = storedFavorites
        // }

        setFavorites(favs)
        const result = favs.find(item => item.id === location.state.key);
        if(result){
            setLikeHymn(favNoFill)
        } else {
            setLikeHymn(favFilled)
        }
        // console.log(favorites, 22)
        localStorage.setItem(localStorageTag, JSON.stringify(favs));
  }, [items]);

    const favorite = () => { 

        setItems(prevItems => {
            const itemIndex = prevItems.findIndex(item => item.id === location.state.key);
            if (itemIndex === -1) {
                setLikeHymn(favNoFill)
              // Add the new item to favorites
              const newItem = { id: location.state.key, isFavorite: true };
              return [...prevItems, newItem];
            } else {
                if(likeHymn == favFilled){
                    setLikeHymn(favNoFill)
                } else {
                    setLikeHymn(favFilled)
                }
              return prevItems.map(item =>
                item.id === location.state.key ? { ...item, isFavorite: !item.isFavorite } : item
              );
            }
          });
       
    }

    const goBack = () => {
        navigate(-1)
    }

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
      };

    const handleSearchHymn =  () => {

    }
    
    const musicTones = () => { //
        const result = checkFileExists()
        if (audio) {
            if (isPlaying) {
              audio.pause();
              setPlayButton(playIcon)
            } else {
              audio.play();
              setPlayButton(pauseIcon)
            }
            setIsPlaying(!isPlaying);
        }
    }

    const prevHymn = () => {

        let dt = null;
        let filter = null;
        let num = null;
        let various = false;
        let newValue = "";
        if(location.state.key.includes("V")){
            num = Number(location.state.key.substr(1,))
            various = true;
            
        } else {
            num = Number(location.state.key)
        }

        if(num == 1 && !various){
            newValue = "V050"
        } else if(num == 1 && various){
            newValue = "1000"
        } else {
            let temp = String(num-1)
            let len = temp.length
            if(len == 3){
                newValue = temp
            }
            if(len == 2){
                newValue = "0" + temp
            }
            if(len == 1){
                newValue = "00" + temp
            }
            if(various){
                newValue = "V" + newValue
            }
        }

        
        if(location.state?.category === "fr"){
            filter = loadFrenchHymn.filter(obj => obj.tno === newValue);
        }else if(location.state?.category === "es"){
            filter = loadSpanishHymn.filter(obj => obj.tno === newValue);
        } else if(location.state?.category.trim() === ""){
            filter = loadYorubaHymn.filter(obj => obj.tno === newValue);
        } else {
            filter = loadHymn.filter(obj => obj.tno === newValue);
        }

        dt = { 
            key: filter[0].tno,
            tname: filter[0].tname,
            verse: filter[0].verse,
            category: filter[0].category,
            navpage: location.state.navpage + 1,
            flavor: location?.state?.flavor,
        };

        const searchItem = filter[0].tno;
        const result = favorites.find(item => item.id === searchItem);
        if(result){
            setLikeHymn(favNoFill)
        } else {
            setLikeHymn(favFilled)
        }
       
        navigate('/hymn-details', { 
            state: dt
        })
          
      }

      const nextHymn = () => {

        let dt = null;
        let filter = null;
        let num = null;
        let various = false;
        let newValue = "";
        if(location.state.key.includes("V")){
            num = Number(location.state.key.substr(1,))
            various = true;
            
        } else {
            num = Number(location.state.key)
        }

        if(num == 1000){
            newValue = "V001"
        } else if(num == 50 && various){
            newValue = "001"
        } else {
            let temp = String(num+1)
            let len = temp.length
            if(len == 3 | len == 4){
                newValue = temp
            }
            if(len == 2){
                newValue = "0" + temp
            }
            if(len == 1){
                newValue = "00" + temp
            }
            if(various){
                newValue = "V" + newValue
            }
        }

        if(location.state?.category === "fr"){
            filter = loadFrenchHymn.filter(obj => obj.tno === newValue);
        }else if(location.state?.category === "es"){
            filter = loadSpanishHymn.filter(obj => obj.tno === newValue);
        } else if(location.state?.category.trim() === ""){
            filter = loadYorubaHymn.filter(obj => obj.tno === newValue);
        } else {
            filter = loadHymn.filter(obj => obj.tno === newValue);
        }

        dt = { 
            key: filter[0].tno,
            tname: filter[0].tname,
            verse: filter[0].verse,
            category: filter[0]?.category,
            navpage: location.state.navpage + 1,
            flavor: location?.state?.flavor,
        };

        const searchItem = filter[0].tno;
        const result = favorites.find(item => item.id === searchItem);
        if(result){
            setLikeHymn(favNoFill)
        } else {
            setLikeHymn(favFilled)
        }
       
        navigate('/hymn-details', { 
            state: dt
        })

      }

    const homeScreen =  () => {
        navigate(-1)
    }     

    return (
        <div className="h-screen bg-white">
           <>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                appElement={document.getElementById('app_loader')}
                contentLabel="Gospel Hymn - Music Notes"
            >
                <div>
                    <Document
                        file={url}
                        onContextMenu={(e) => e.preventDefault()}
                        className="pdf-container">
                        <Page pageNumber={1} />
                    </Document>
                </div>
                <button className="h-10 font-bold text-white bg-red-400 w-full focus:outline-none" onClick={closeModal}>Close</button>
            </Modal>
        </>
        <div>
            <HymnPicker
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                onOptionSelect={handleOptionSelect}
                category={currentCategory}
            />
        </div>
            {/* <div className='w-4/5'>
                <p className='mt-2 w-full font-bold text-2xl'>GOSPEL HYMN: {location?.state?.key}</p>
                <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-300"></hr>
            </div> */}

            <div className="sticky top-0 z-10">
                <div className="toolbar flex items-center">
                    <div className="go-back">
                        <MdArrowBack size="26" className="text-white" onClick={goBack}/>
                    </div>
                    <h1 className="toolbar-title pl-1 text-md"><span className="uppercase">GOSPEL HYMN: {location?.state?.key}</span></h1>
                </div>
            </div>

            <div className="pl-5 sm:pr-3 md:pl-10 lg:pl-10 pt-7 z-0 overflow-y-scroll h-screen bg-white">
                <div className="flex relative">
                    <div className="w-4/5 md:w-2/5">
                        <p className="md:text-2xl sm:text-sm">{location?.state?.tname}</p>
                        <p className="text-gray-400">
                            {location.state?.category?.trim() == "" ? ("Yoruba Version") : null}
                            {location.state?.category == "fr" ? ("French Version") : null}
                            {location.state?.category == "es" ? ("Spanish Version") : null}
                            {location.state?.category?.trim() !== "" &&  location?.state?.category !== "es" &&  location?.state?.category !== "fr" ? ("English Version") : null}
                        </p>
                    </div>
                    <div className=""></div>
                    <div className="flex flex-col ml-3 md:flex top-0 flex md:flex-row justify-center md:ml-10">
                        <img style={{height: '40px'}} src={navigationUp} onClick={nextHymn}/>
                        <img style={{height: '40px'}}  className="md:pl-5 mt-4 md:mt-0" src={navigationDown} onClick={()=>prevHymn()} />
                    </div>
                </div>

                <div className="lg:w-3/6 md:w-4/6 sm:w-5/6 md:mr-0 mr-7">
                    <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-200"></hr>
                    <div className="flex flex-row my-5 justify-around h-10">
                        <div className="flex">
                            <img src={documentIcon} onClick={()=>musicNotes()}/>
                        </div>
                        <div className="w-0.5 h-full divide-x bg-gray-200"></div>
                        <div className="flex">
                            <img src={likeHymn} onClick={()=>favorite()}/>
                        </div>
                        <div className="w-0.5 h-full divide-x bg-gray-200"></div>
                        <div className="flex">
                            <img src={switchIcon} onClick={()=>handleOpenDialog()}/>
                        </div>
                        <div className="w-0.5 h-full divide-x bg-gray-200"></div>
                        <div className="flex">
                            <img src={playButton} onClick={()=>musicTones()}/>
                        </div>
                        <div className="w-0.5 h-full divide-x bg-gray-200"></div>
                        <div className="flex">
                            <img src={homeIcon} onClick={()=>homeScreen()}/>
                        </div>
                    </div>
                    <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-200"></hr>
                </div>
                
                <p className="text-md mt-7 w-11/12 md:w-12/12" style={{ whiteSpace: 'pre-line' }}>{location?.state?.verse}</p>

                <p className="text-center text-sm md:text-md lg:text-lg md:text-center lg:text-center md:pl-0 lg:pl-0 text-gray-500 mt-7 md:w-1/5 lg:w-1/5 w-4/4 sm:ml-5 md:ml-0">Have a question?</p>
                <div className="flex my-5 md:w-1/5 w-4/4 justify-center overflow-y-scroll"
                    style={{height: '7rem'}}
                >
                    <button onClick={openGmailCompose} className="h-10 text-sm md:text-md lg:text-lg font-bold text-white bg-red-400 w-2/4 md:w-full lg:w-full focus:outline-none" >SUBMIT A REQUEST</button>
                </div>

            </div>
        </div>
    )
}

export default CardInfo