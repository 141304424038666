import React, { useState, useRef, memo, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import DialogHymn from '../components/DialogHymn'
import Favorites from '../components/Favorites'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CiShare1} from 'react-icons/ci';
import {BiSolidMessageSquareDots} from 'react-icons/bi';
import {HiOutlineTerminal} from 'react-icons/hi';
import { motion} from 'framer-motion';

const isAndroid = /Android/i.test(navigator.userAgent);
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

const ExternalLinks = React.memo(({ shareApp, router}) => {

  let ios_msg = "Check out the Gospel Hymn Book + Audio app on App store https://apps.apple.com/us/app/gospel-hymn-book-audio/id1497999278 It is super amazing!";

  let android_msg = "Check out the Gospel Hymn Book + Audio app on Google Play https://play.google.com/store/apps/details?id=com.adediranife.cachymn It is super amazing!";

  // let web_msg = "Check out the Gospel Hymn Book + Audio app on Google Play https://play.google.com/store/apps/details?id=com.adediranife.cachymn or App store https://apps.apple.com/us/app/cac-gospel-hymn-book/id1497999278 It is super amazing!";
  
  return (
    <div className="z-10 hide-scrollbar bg-transparent" style={{height: `100%`}} >

        <div className="p-5" style={{
            position: 'absolute',
            bottom: (!isIOS && !isAndroid ? 0 : isIOS ? 100 : 90),
            zIndex: 5,
            }}>

              {isAndroid ? (
                <div className="">
                  <CiShare1 size={40} onClick={(e)=> shareApp(e, android_msg)} style={{
                      position: 'relative',
                      color: 'white',
                      left: '0vw',
                      }}  />
                </div>
                ) : null}

                {isIOS ? (
                <div className="">
                  <CiShare1 size={40} onClick={(e)=> shareApp(e, ios_msg)} style={{
                      position: 'relative',
                      color: 'white',
                      left: '0vw',
                      }}  />
                </div>
                ) : null}

                {!isIOS && !isAndroid ? (
                <div className="">
                  <CiShare1 size={40} onClick={(e)=> shareApp(e, android_msg)} style={{
                      position: 'relative',
                      color: 'white',
                      left: '0vw',
                      }}  />
                </div>
                ) : null}

                <div className=''>
                  <BiSolidMessageSquareDots size={40} onClick={() => router('contact-us')} className="mt-4" style={{
                      position: 'relative',
                      color: '#fff',
                      left: '0vw',
                      }}  />
                </div>
               
               <div>
                <HiOutlineTerminal size={40} onClick={() => router('privacy/')} className="mt-4" style={{
                    position: 'relative',
                    color: '#fff',
                    left: '0vw',
                    }}  />
               </div>
                              
                <div className="">
                    <ToastContainer position="bottom-left" autoClose={2500}/>
                </div> 

        </div>
    </div>
  )
})


const Carousel = ({ routePages, navigate, items, shareIcon, setChangeBackground, dbContext }) => {
    const [index, setIndex] = useState(0);
    const [copied, setCopied] = useState(false);
    const [isShareLoading, setIsShareLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [startX, setStartX] = useState(null);

    const shareApp = (event, message) => {
        if(isShareLoading){
            return
        }
        setIsShareLoading(true)
        const textarea = document.createElement('textarea');
        textarea.value = message
        document.body.appendChild(textarea);
        
        // Select the text and copy it to the clipboard
        textarea.select();
        document.execCommand('copy');

        event.preventDefault();

        if(!isIOS){
          toast('Share Link information has been copied to clipboard!');
        } else {
          alert('Share link information has been copied to clipboard!')
        } 
    
        // Cleanup and update copied state
        document.body.removeChild(textarea);
        setCopied(true);
    
        // Reset the copied state after a delay
        setTimeout(() => {
          setCopied(false);
          setIsShareLoading(false)
        }, 3000);
    }

      const router = (nav) => {
          navigate('/'+nav)
      }

    const backgroundRefresh = (category) => {
        if (category) { 
            if(category === "yoruba"){
                setActiveIndex(1);
            }
            else if(category === "french"){
                setActiveIndex(2);
            }
            else if(category === "spanish"){
                setActiveIndex(3);
            }
            else if(category === "english"){
              //
            }
            else if(category === "author"){
                setActiveIndex(4);
            }
            else if(category === "gospel"){
                setActiveIndex(6);
            } else if(category === "favorites"){
                setActiveIndex(5);
            }
        }
    }

    const handleSlide = (direction) => {
      const newIndex = (activeIndex + direction + items.length) % items.length;
      // setIndex(0)
      setActiveIndex(newIndex);
    };
  
    const handleTouchStart = (e) => {
      setStartX(e.touches[0].clientX);
    };
  
    const handleTouchEnd = (e) => {
      if (startX === null) return;
      const endX = e.changedTouches[0].clientX;
      const deltaX = startX - endX;
  
      if (deltaX > 50) {
        // Swipe left
        handleSlide(1);
      } else if (deltaX < -50) {
        // Swipe right
        handleSlide(-1);
      }
  
      setStartX(null);
    };

  return (

    <div className=''>

    <Routes>
        <Route path="/english-hymn" element={<DialogHymn version="ENGLISH HYMN" versionHeader={items[0].img_header} hymnDb={dbContext.loadHymn} backgroundRefresh={backgroundRefresh}  flavor="english" />}/>

        <Route path="/yoruba-hymn" element={<DialogHymn version="YORUBA HYMN" versionHeader={items[1].img_header} hymnDb={dbContext.loadYorubaHymn} backgroundRefresh={backgroundRefresh}  flavor="yoruba"/>}/>

        <Route path="/french-hymn" element={<DialogHymn version="FRENCH HYMN" versionHeader={items[2].img_header} hymnDb={dbContext.loadFrenchHymn} backgroundRefresh={backgroundRefresh} flavor="french"/>}/>

        <Route path="/spanish-hymn" element={<DialogHymn version="SPANISH HYMN" versionHeader={items[3].img_header} hymnDb={dbContext.loadSpanishHymn} backgroundRefresh={backgroundRefresh}  flavor="spanish"/>}/>
        
        <Route path="/author-review" element={<DialogHymn version="AUTHOR REVIEW" versionHeader={items[4].img_header} hymnDb={dbContext.loadAuthorReview} backgroundRefresh={backgroundRefresh}  flavor="author"/>}/>

        <Route path="/gospel" element={<DialogHymn version="THE GOSPEL" versionHeader={items[6].img_header} hymnDb={dbContext.loadDoctrine} backgroundRefresh={backgroundRefresh}  flavor="gospel"/>}/>

        <Route path="/favorites" element={<Favorites hymnDb={dbContext.loadHymn} backgroundRefresh={backgroundRefresh}  flavor="favorites"/>}/>
    </Routes>

   <div
      className=""
      style={{
        display: 'flex',
        overflow: 'hidden',
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={(e) => setStartX(e.clientX)}
      onMouseUp={(e) => {
        if (startX === null) return;
        const endX = e.clientX;
        const deltaX = startX - endX;

        if (deltaX > 50) {
          // Dragged left
          handleSlide(1);
        } else if (deltaX < -50) {
          // Dragged right
          handleSlide(-1);
        }
        setStartX(null);
      }}
    >
      {items.map((item, ind) => (
        
        <motion.div
        key={ind}
        className="w-screen h-screen bg-center overflow-hidden bg-cover bg-no-repeat text-center"
        style={{
          background: `url("${item.img_background}")`,
          backgroundPosition: 'top left',
          flex: 1,
          transform: `translate3d(${(index - activeIndex) * 100}%, 0, 0)`,
          opacity: !!true ? 1 : 0,
        }}
        initial={{ x: (index - activeIndex) * 100 + '%', opacity: 0 }}
        animate={{ x: (index - activeIndex) * 100 + '%', opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      >
           <div className="absolute h-full text-center opacity-80">
              <p className="pt-16 font-bold text-white w-screen text-center text-8xl">
                {`${item.id}`} <span className="text-gray-400">/ 8</span>
              </p>
            </div>
            <div className="grid justify-items-center h-full">
              <div className="relative mt-48 w-80 w-10/12">
                <img
                  src={item.img_header}
                  className="flex w-screen"
                  style={{ height: '45%' }}
                  onClick={() => routePages(item.category, navigate)}
                />
                <h3 className="absolute w-screen text-3xl text-white top-14 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  {item.name}
                </h3>
              </div>
            </div>

        </motion.div>
      ))}
    </div>  
      <ExternalLinks router={router} shareApp={shareApp} />

    </div>
  );
};

export default memo(Carousel);

