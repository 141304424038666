import React, {useEffect, useState} from 'react'
import Modal from 'react-modal';

const HymnPicker = ({ isOpen, onClose, onOptionSelect, category }) => {

    const [selectedOption, setSelectedOption] = useState(null);
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onOptionSelect(option)
    };

    // const handleConfirm = () => {

    //     if (selectedOption) {
    //         onOptionSelect(selectedOption);
    //     }
    //     setSelectedOption(null);
    //     onClose();
    // };

    const listItems = [
        { value: 'english', disabled: false },
        { value: 'yoruba', disabled: false },
        { value: 'french', disabled: false },
        { value: 'spanish', disabled: false },
      ];

    useEffect(()=> {

        for(let index in listItems){
            if(listItems[index].value === category){
                listItems[index].disabled = true;
                break
            }
        }

    }, [listItems])
    

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Alert Dialog">
      <h2 className="mb-5 text-lg font-semibold">What version do you want to read?</h2>
      <ul className="list-inside dark:text-gray-800">
        {listItems.map((item) => (
          <li
            className="p-3 bg-gray-200 mb-3 rounded hover:bg-red-200"
            key={item.value}
            onClick={() => handleOptionClick(item)}
            style={{ cursor: item.disabled ? 'not-allowed' : 'pointer',
                     textTransform: 'capitalize' }}
          >
            {item.value}
          </li>
        ))}
      </ul>
      <button className="mt-10 h-10 font-bold text-white bg-red-400 w-full focus:outline-none" onClick={onClose}>Cancel</button>
    </Modal>
  )
}

export default HymnPicker
