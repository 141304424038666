import  React, {useState, useEffect} from "react"
import {useLocation, useNavigate} from 'react-router-dom';

const AboutViewDesktop = ({assets}) => {

    const localStorageTag = "hymn.adediranife.favorites"
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className="">About</div>
    )
}

export default AboutViewDesktop