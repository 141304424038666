import React, { useState, useEffect } from 'react';
import './styles/SearchHymn.css'; // Import your CSS file for styling
import {useLocation, useNavigate, useNavigationType} from 'react-router-dom';
import {MdArrowBack} from 'react-icons/md';
import { useDb } from './DbContext';

const Favorites = ({hymnDb, backgroundRefresh, mode='mobile'}) => {
    const localStorageTag = "hymn.adediranife.favorites"
    const location = useLocation();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const navType = useNavigationType();
    const [emptyPage, setEmptyPage] = useState(true);
    const [index, setIndex] = useState(0);

    const dbContext = useDb();
    useEffect(() => {
        if (!dbContext) {
            // fetching records...
        }
    }, [dbContext]);

    useEffect(()=> {
        const storedFavorites = JSON.parse(localStorage.getItem(localStorageTag)) || [];
        if(storedFavorites.length){
            setEmptyPage(false)
            setIndex(storedFavorites.length)
        }
        setItems(storedFavorites);

    }, [])

    const handleBackNavigation = () => {
        backgroundRefresh("favorites")
      };

    useEffect(() => {
        if (navType === "POP" && location.key !== "default") {
            handleBackNavigation();
        }
      }, [location]);

    const viewHymn = (key, flavor) => {

        if(mode == 'mobile'){
            let filter = hymnDb.filter(item => item.tno === key)[0]
            let data = { 
                key,
                tname: filter.tname,
                verse: filter.verse,
                category: filter.category,
                flavor,
                navpage: 1
            };
            navigate('/view-hymn', { 
                state: data
            })
        }

        if(mode == 'desktop'){
            let filter = dbContext.loadHymn.filter(item => item.tno === key)[0]
            let data = { 
                key,
                tname: filter.tname,
                verse: filter.verse,
                category: filter.category,
                flavor,
                navpage: 1
            };
            navigate('/hymn-details', { 
                state: data
            })
        }
    }

    const goBack = () => {
        navigate(-1)
    }

  return (
    <div className="absolute z-20 w-screen overflow-hidden bg-white">
    <div className="search-page">
      <div className="toolbar flex items-center">
        <div className="go-back">
            <MdArrowBack size="26" className="text-white" onClick={goBack}/>
        </div>
        <h1 className="toolbar-title pl-1 text-md"><span className="uppercase">Favorites</span></h1>
      </div>

      {index < 10 ? (
        <div className="flex justify-center items-center"
        style={{
            height: (emptyPage) ? "100vh" : "auto"
        }}>
          <div className="bg-white p-5 mt-4 rounded shadow-md"
          style={{
            marginTop: (emptyPage) ? "0rem" : "1rem"
        }}>
            <h1 className="text-gray-500">Welcome to your most loved hymns</h1>
          </div>
        </div> ) : null}

        {!emptyPage ? (
        <div className="flex flex-col items-center mt-5 w-screen">
            <ul className="list-none divide-y overflow-y-scroll pb-5 no-scrollbar w-screen pl-5 pr-5" style={{height: `70vh`, }}>
                {
                    items ? 
                    (
                        items.map((item, index)=>(
                            <li key={item.id} className="py-3" onClick={() => viewHymn(item.id, "english")}>
                                <div>
                                    <p>Hymn {item.id}</p>
                                </div>
                            </li>
                        ))
                    
                    ) : null
                }
            </ul>
        </div> ): null}
      </div>
    </div>
  )
}

export default Favorites
