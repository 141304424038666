import React, { useState, useEffect, useRef, memo } from 'react';
import axios from 'axios';
import { useDb } from '../components/DbContext'; // Import useDb from the DbContext
import data from '../utils/data'
import {useLocation, useNavigate, Link, Route, Routes } from 'react-router-dom';
import NoticeDialog from '../components/NoticeDialog';
import shareIcon from '../assets/share_window.svg'
import Carousel from './Carousel';
import SplashScreen from './SplashScreen';

// const pages = data().pages;
const pages = data().items;

const isAndroid = /Android/i.test(navigator.userAgent);
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

const Dashboard = () => {

    const [isSplashLoading, setIsSplashLoading] = useState(true);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const scrollRef = useRef(null);

    const dbContext = useDb();
        useEffect(() => {
            if (!dbContext) {
              // fetching records...
            }
        }, [dbContext]);

        // useEffect(() => {
        //     const lc_splash = 'hymn.adediranife.visited'
        //     const hasVisited = localStorage.getItem(lc_splash);
        //     if (hasVisited) {
        //         setIsSplashLoading(false);
        //     } else {
        //     localStorage.setItem(lc_splash, 'true');
        //     setTimeout(() => {
        //     setIsSplashLoading(false);
        //     }, 4000); 
        //     }
        // }, []);

        const location = useLocation();
        const navigate = useNavigate();
        const [changeBackground, setChangeBackground] = useState(pages[0].img_background);
        const [showAboutDialog, setShowAboutDialog] = useState(false);
        const openAboutDialog = () => {
            setShowAboutDialog(true);
        };

        const closeAboutDialog = () => {
            setShowAboutDialog(false);
        };

        const routePages =  (category, navigate) => {
            if(category === "english"){
                navigate('/dashboard/english-hymn')
            } else if(category === "yoruba"){
                navigate('/dashboard/yoruba-hymn')
            } else if(category === "french"){
                navigate('/dashboard/french-hymn')
            } else if(category === "spanish"){
                navigate('/dashboard/spanish-hymn')
            } else if(category === "author"){
                navigate('/dashboard/author-review')
            } else if(category === "gospel"){
                navigate('/dashboard/gospel')
            } else if(category === "favorites"){
                navigate('/dashboard/favorites')
            }  else if(category === "aboutus"){
                setShowAboutDialog(true)
            } 
        }

        // backgroundColor: 'rgb(0 0 0 / 0.8)',
        // backgroundBlendMode: 'multiply'
  return (

    <>
            <div className="relative h-screen bg-no-repeat" 
            style={{
                backgroundImage: (!isAndroid && !isIOS ? `url("${changeBackground}")` : `url("${pages[7].img_background}")`),
                backgroundSize:  (!isAndroid && !isIOS ? "cover" : "auto"),
            }} 
            >
        
            {showAboutDialog && (
                    <NoticeDialog
                    message="Gospel hymn book was developed with love to enable people to have personal and community fellowship with God. A lot of resources available for edification and soul nourishment. If you have any questions, please feel free to reach us via support@gospelhymns.cartglu.com. Stay blessed."
                    onClose={closeAboutDialog}
                    />
                )} 
        
              <Carousel items={pages} routePages={routePages} shareIcon={shareIcon}
                    navigate={navigate} pages={pages} dbContext={dbContext}
                    setChangeBackground={setChangeBackground} />
        
              {/* <div className="z-0 flex justify-center min-h-full bg-black/8 bg-no-repeat bg-blend-multiply overflow-hidden">
              </div> */}
            </div>
        
             
    </>
  );
};

export default memo(Dashboard);
