import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HiOutlineSearchCircle } from 'react-icons/hi';

const Navbar = ({ searchTerm, setSearchTerm }) => {
  const navigate = useNavigate();

  const handleKeyPress = () => {
    navigate('/search', {
      state: {
        searchTerm: searchTerm
      }
    })
  }

    return (
      <div className="flex gap-2 md:gap-5 w-full mt-5">
        <div className="flex justify-start w-5/5 items-center p-3 rounded-md border-none outline-none focus-within:shadow-sm">
          <HiOutlineSearchCircle fontSize={21} className="ml-1" />
          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
            value={searchTerm}
            // onFocus={() => navigate('/search')}
            onKeyDown={handleKeyPress}
            className="p-2 mx-3 w-full bg-white outline-none"
            style={{
                width: '100%'
            }}
          />
        </div>
      </div>
    );
};

export default Navbar;